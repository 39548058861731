<template>
    <div>
        <div @click="divlick()">
        <!-- <el-cascader-panel :options="options" :props="{ expandTrigger: 'hover' }"></el-cascader-panel> -->
          <el-input
              v-model="queryInfo.query"
              clearable
              size="small"
              placeholder="输入机构名称搜索"
              style="width: 200px;"
              class="filter-item"
              @keyup.enter.native="getTableData()"
          />
          <span>
              <el-button style="margin-left:10px" class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
          </span>
          <span style="margin-left:10px"></span>
          <el-select
            v-model="queryInfo.business_STATUS"
            clearable
            size="small"
            placeholder="业务状态"
            class="filter-item"
            style="width: 120px"
            @change="getTableData()"
          >
            <el-option
              v-for="item in businessOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <span style="margin-left:10px"></span>
          <el-select
            v-model="queryInfo.sales_ID"
            clearable
            size="small"
            placeholder="客户经理"
            class="filter-item"
            style="width: 120px"
            @change="getTableData()"
          >
            <el-option
              v-for="item in salesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <div  class="crdc-opts">
            <span>
              <slot name="left" />
              <el-button
                class="filter-item"
                size="mini"
                type="primary"
                icon="el-icon-plus"
                @click="add()"
              >
                新增
              </el-button>
            </span>
            <!--右侧-->
              <slot name="right" />
            <el-button-group class="crud-opts-right">
                <el-button
                  size="mini"
                  icon="el-icon-refresh"
                  @click="getTableData()"
                />
              </el-button-group>
          </div>
          <el-card>
            <el-table
            :data="tableData"  highlight-current-row
            @current-change="companyCurrentChange"
            @row-contextmenu="rightClick"
            @row-dblclick="rowDblClick"
            ref="singleTable"
            @sort-change='sortChange'
            border stripe
            :row-style="{height: '0'}"
            :cell-style="{padding: '3px'}"
            style="width: 100%">
            <el-table-column
              fixed
              prop="org_NAME"
              label="机构全称"
              sortable='custom'
              width="260"
              show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button @click="toManagePage(scope.row.org_ID)" type="text">{{scope.row.org_NAME}}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              sortable='custom'
              prop="org_ADDRESS"
              label="地址"
              width="350">
            </el-table-column>
            <el-table-column
              prop="org_CONTACT_NAME"
              label="联系人"
              sortable='custom'
              width="90">
            </el-table-column>
            <el-table-column
              prop="org_CONTACT_PHONE"
              label="联系电话"
              sortable='custom'
              :show-overflow-tooltip="true"
              width="110">
            </el-table-column>
            <el-table-column
              prop="org_CONTACT_EMAIL"
              label="联系邮箱"
              width="150" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
              prop="create_DATE"
              label="报备日期"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="sales_NAME"
              label="客户经理"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="supportor_NAME"
              label="销售支持"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="business_STATUS_NAME"
              label="业务状态"
              sortable='custom'
              width="100">
            </el-table-column>
            <el-table-column
              prop="business_ACCOUNTS"
              label="协议账户数"
              width="110">
            </el-table-column>
            <el-table-column
              prop="valid_ACCOUNT"
              label="有效账户数"
              sortable='custom'
              width="110">
            </el-table-column>
            <el-table-column
              prop="business_END"
              label="业务到期"
              sortable='custom'
              width="100">
            </el-table-column>
            <!-- <el-table-column
              label="操作" align="center"
              width="180">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)"  ></el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteFrom(scope.$index, scope.row)"></el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pagenum"
            :page-sizes="[2, 5, 10, 15]"
            :page-size="queryInfo.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totle"
          ></el-pagination>
          <el-dialog append-to-body  title="修改客户资料" :visible.sync="addDialogVisible" width="35%" @close='resetForm'>
            <el-form ref="form" :rules="rules" class="demo-ruleForm" :model="form"  label-width="80px" >
              <div>
              <el-form-item label="机构全称" prop="org_NAME" >
                <el-col :span="11">
                  <el-input v-model="form.org_NAME"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="地址" prop="org_ADDRESS" >
                <el-col :span="11">
                  <el-input v-model="form.org_ADDRESS"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="联系人" prop="org_CONTACT_NAME" >
                <el-col :span="11">
                  <el-input v-model="form.org_CONTACT_NAME"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="电话" prop="org_CONTACT_PHONE" >
                <el-col :span="11">
                  <el-input v-model="form.org_CONTACT_PHONE"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="邮箱" prop="org_CONTACT_EMAIL" >
                <el-col :span="11">
                  <el-input v-model="form.org_CONTACT_EMAIL"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="销售合作" prop="sale_COOPERATE">
                <el-select  v-model="form.sale_COOPERATE" placeholder="请选择">
                  <el-option
                    v-for="item in isOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="text" @click="resetForm()" >取消</el-button>
              <el-button v-if="isAdd == true"  type="primary" @click="submitFrom('form')">确认</el-button>
              <el-button v-if="isAdd == false" type="primary" @click="updateFrom('form')">修改</el-button>
            </div>
          </el-dialog>
          <el-dialog append-to-body  title="开通付费业务" :visible.sync="payVisible" width="35%" @close='resetForm2'>
            <el-form ref="form2" :rules="rules" class="demo-ruleForm" :model="form"  label-width="110px" >
              <div>
              <el-form-item label="机构全称" prop="org_NAME1" >
                {{form.org_NAME}}
              </el-form-item>
              <el-form-item label="协议账号数" prop="business_ACCOUNTS" >
                <el-col :span="11">
                  <el-input v-model="form.business_ACCOUNTS"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="到期日期" prop="business_END" >
                <el-col :span="11">
                  <el-date-picker
                    v-model="form.business_END"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-col>
              </el-form-item>
              </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="text" @click="resetForm2()" >取消</el-button>
              <el-button type="primary" @click="submitFromOpen('form2')">确认</el-button>
            </div>
          </el-dialog>
          </el-card>
        </div>
        <div @mouseleave="leave()" v-show="contextMenuVisible">
          <el-cascader-panel :options="options1"
          v-model="cascaderValue"
          :style="{left:left+'px',top:top+'px'}"
          :props="{ expandTrigger: 'hover' }"
          @change="change"
          @expand-change="expandchange"
          class="contextmenu"></el-cascader-panel>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Org',
  created () { //  初始化
    this.getTableData()
    this.querySalesOptions()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/org', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    async querySalesOptions () {
      const { data: res } = await this.$http.get('query/sales/node')
      this.salesOptions = res
    },
    companyCurrentChange (val) {
    },
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    add () {
      this.queryRoleOptions()
      this.addDialogVisible = true
      this.isAdd = true
    },
    async handleEdit (index, row) {
      this.queryRoleOptions()
      this.addDialogVisible = true
      this.isAdd = false
      const { data: res } = await this.$http.get('query/user/by/id', {
        params: { user_ID: row.user_ID }
      })
      this.form = res
      this.form.password = ''
    },
    resetForm () {
      this.cascaderValue = ''
      this.addDialogVisible = false
      this.$refs.form.resetFields()
    },
    resetForm2 () {
      this.cascaderValue = ''
      this.addDialogVisible = false
      this.$refs.form2.resetFields()
    },
    async submitFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isPass = true
        }
      })
      if (isPass) {
        await this.$http.post('insert/org', this.form)
        this.resetForm()
        this.getTableData()
      }
    },
    async submitFromOpen (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isPass = true
        }
      })
      if (isPass) {
        await this.$http.post('update/accounts/by/id', this.form)
        this.resetForm()
        this.getTableData()
        this.payVisible = false
      }
    },
    async updateFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isPass = true
        }
      })
      if (isPass) {
        await this.$http.post('update/org', this.form)
        this.resetForm()
        this.getTableData()
      }
    },
    async deleteFrom (index, row) {
      // await this.$http.post('delete/banner', row)
      this.getTableData()
    },
    async queryRoleOptions (index, row) {
      const { data: res } = await this.$http.get('query/role/node')
      this.roleOptions = res
    },
    async rightClick (row, column, event) {
      event.preventDefault()// 防止默认菜单弹出
      this.$refs.singleTable.setCurrentRow(row)
      const { data: res1 } = await this.$http.get('query/org/node')
      this.options1 = res1
      const { data: res } = await this.$http.get('query/org/by/id', {
        params: { org_ID: row.org_ID }
      })
      const { data: salesRes } = await this.$http.get('query/org/by/user/id', {
        params: { user_ID: row.sales_ID }
      })
      if (salesRes.org_ID === undefined) {
        // this.cascaderValue = '3,'
        this.rightForm.sales_ORG_ID = '3,'
      } else {
        // this.cascaderValue = '3,' + salesRes.org_ID + ',' + row.sales_ID
        this.rightForm.sales_ORG_ID = '3,' + salesRes.org_ID + ',' + row.sales_ID
      }
      const { data: supportRes } = await this.$http.get('query/org/by/user/id', {
        params: { user_ID: row.supportor_ID }
      })
      // this.rightForm.supportor_ORG_ID = supportRes.org_ID
      if (supportRes.org_ID === undefined) {
        // this.cascaderValue = '4,'
        this.rightForm.supportor_ORG_ID = this.cascaderValue = '4,'
      } else {
        // this.cascaderValue = '4,' + supportRes.org_ID + ',' + row.sales_ID
        this.rightForm.supportor_ORG_ID = '4,' + supportRes.org_ID + ',' + row.supportor_ID
      }
      this.form = res
      this.left = event.clientX
      this.top = event.clientY
      this.contextMenuVisible = true
    },
    leave () {
      this.cascaderValue = ''
      this.contextMenuVisible = false
    },
    async change (value) {
      if (value[0] === '1') {
        this.addDialogVisible = true
      } else if (value[0] === '2') {
        this.payVisible = true
      } else if (value[0] === '3') {
        if (value[1] !== '') {
          this.form.sales_ID = value[2]
        } else {
          this.form.sales_ID = null
        }
        await this.$http.post('update/salesid/by/id', this.form)
      } else if (value[0] === '4') {
        if (value[1] !== '') {
          this.form.supportor_ID = value[2]
        } else {
          this.form.supportor_ID = null
        }
        await this.$http.post('update/supportorid/by/id', this.form)
      }
      this.contextMenuVisible = false
      this.getTableData()
    },
    expandchange (row) {
      if (row[0] === '3') {
        this.cascaderValue = this.rightForm.sales_ORG_ID
      } else if (row[0] === '4') {
        this.cascaderValue = this.rightForm.supportor_ORG_ID
      } else {
        this.cascaderValue = ''
      }
    },
    divlick () {
      this.contextMenuVisible = false
      this.cascaderValue = ''
    },
    rowDblClick (row, column, event) {
      this.toManagePage(row.org_ID)
    },
    toManagePage (orgId) {
      // this.$router.push('/system/platform/user')
      this.$router.push({ path: '/system/platform/routerUser', query: { selected: orgId } })
    },
    sortChange (column, prop, order) {
      // 可以打印一下该函数的参数就明白了
      // 下面的if判断根据自己的需要些我后台设置的只能识别desc与asc
      if (column.order === 'descending') {
        this.queryInfo.isAsc = 'desc'
      } else if (column.order === 'ascending') {
        this.queryInfo.isAsc = 'asc'
      } else {
        this.queryInfo.isAsc = ''
      }
      // 排序的字段传给后台
      this.queryInfo.orderByColumn = column.prop
      // 传入查询参数，重新查询一次
      this.getTableData()
    }
  },
  data () {
    return {
      form: {
        org_NAME: '',
        org_ADDRESS: '',
        org_CONTACT_NAME: '',
        org_CONTACT_PHONE: '',
        org_CONTACT_EMAIL: '',
        sale_COOPERATE: '',
        business_ACCOUNTS: '',
        business_END: '',
        sales_ID: ''
      },
      rightForm: {
        sales_ORG_ID: '',
        supportor_ORG_ID: ''
      },
      queryInfo: {
        query: '',
        business_STATUS: '',
        sales_ID: '',
        pagenum: 1,
        pagesize: 10
      },
      totle: 0,
      tableData: [],
      isAdd: '',
      addDialogVisible: false,
      payVisible: false,
      salesOptions: [],
      isOptions: [{
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      businessOptions: [{
        value: 100,
        label: '全部'
      }, {
        value: 101,
        label: '未开通'
      }, {
        value: 1,
        label: '未到期'
      }, {
        value: 2,
        label: '一周内到期'
      }, {
        value: 3,
        label: '一月内到期'
      }],
      roleOptions: [],
      sexOptions: [{
        value: '0',
        label: '女'
      }, {
        value: '1',
        label: '男'
      }],
      value1: ['1', '2'],
      rules: {
        org_NAME: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        org_ADDRESS: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        org_CONTACT_NAME: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        org_CONTACT_PHONE: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        org_CONTACT_EMAIL: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        sale_COOPERATE: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        business_ACCOUNTS: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        business_END: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      },
      cascaderValue: '',
      contextMenuVisible: false,
      left: '',
      top: '',
      options1: [{
        value: '1',
        label: '修改资料'
      }, {
        value: '2',
        label: '开通付费业务'
      }, {
        value: '3',
        label: '客户经理',
        children: [{
          value: '4',
          label: '未指派'
        }, {
          value: '5',
          label: '深圳市区量龙咨询管理有限公司',
          children: [{
            value: 'radio',
            label: 'Radio 单选框'
          }, {
            value: 'checkbox',
            label: 'Checkbox 多选框'
          }, {
            value: 'input',
            label: 'Input 输入框'
          }, {
            value: 'input-number',
            label: 'InputNumber 计数器'
          }, {
            value: 'select',
            label: 'Select 选择器'
          }, {
            value: 'cascader',
            label: 'Cascader 级联选择器'
          }, {
            value: 'switch',
            label: 'Switch 开关'
          }, {
            value: 'slider',
            label: 'Slider 滑块'
          }, {
            value: 'time-picker',
            label: 'TimePicker 时间选择器'
          }, {
            value: 'date-picker',
            label: 'DatePicker 日期选择器'
          }, {
            value: 'datetime-picker',
            label: 'DateTimePicker 日期时间选择器'
          }, {
            value: 'upload',
            label: 'Upload 上传'
          }, {
            value: 'rate',
            label: 'Rate 评分'
          }, {
            value: 'form',
            label: 'Form 表单'
          }]
        }, {
          value: 'data',
          label: '鹏扬基金管理有限公司',
          children: [{
            value: 'table',
            label: 'Table 表格'
          }, {
            value: 'tag',
            label: 'Tag 标签'
          }, {
            value: 'progress',
            label: 'Progress 进度条'
          }, {
            value: 'tree',
            label: 'Tree 树形控件'
          }, {
            value: 'pagination',
            label: 'Pagination 分页'
          }, {
            value: 'badge',
            label: 'Badge 标记'
          }]
        }, {
          value: 'notice',
          label: '浪潮金融信息技术有限公司',
          children: [{
            value: 'alert',
            label: 'Alert 警告'
          }, {
            value: 'loading',
            label: 'Loading 加载'
          }, {
            value: 'loading',
            label: 'Loading 加载'
          }, {
            value: 'loading',
            label: 'Loading 加载'
          }, {
            value: 'message',
            label: 'Message 消息提示'
          }, {
            value: 'message-box',
            label: 'MessageBox 弹框'
          }, {
            value: 'notification',
            label: 'Notification 通知'
          }]
        }]
      }, {
        value: 'ziyuan',
        label: '资源'
      }]
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
.contextmenu {
  margin: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.contextmenu li {
    margin: 0;
    padding: 0px 22px;

}
.contextmenu li:hover {
    background: #f2f2f2;
    cursor: pointer;
}
.contextmenu li button{
   color: #2c3e50;
}
</style>
